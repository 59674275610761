import styled from "styled-components";

function Footer() {
    return (
        <FooterStyled>
            <div>
                <p>© nobody 2022</p>
            </div>
        </FooterStyled>
    )
}

const FooterStyled = styled.footer`
    flex: 1 0;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    border-top: 1px solid var(--primary-color);
    border-right: 1px solid var(--primary-color);
    padding: 1rem;

    p {
        font-size: 0.5rem;
    }

`;

export default Footer;
import styled from "styled-components";

function LinksList({ elements }) {
    return (
        <StyledUL>
            {
                elements.map((child, i) => (
                    <li key={"links-list-" + i}>
                        <StyledSpan className="link">{child[0]}</StyledSpan>
                        <span className="icon">{child[2]}</span>
                        <span className="description"><small>{child[1]}</small></span>
                    </li>
                ))
            }
        </StyledUL>
    );
}

const StyledSpan = styled.span`
    transform-style: preserve-3d;
    position: relative;
    perspective: 50px;
    border-bottom: 1px solid var(--primary-color);
    flex: 1 1;
    white-space: nowrap;
    padding: 0.25rem;
    padding-left: 0.75rem;
    
    &:hover {
        border-bottom: 1px solid var(--primary-color-lighten);
    }
    
    &::before {
        content: "";
        display: inline-flex;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(90deg, var(--primary-color-darken) 50%, transparent 50%);
        opacity: 0.5;
        background-size: 200% 200%;
        background-position: 100% 0;
        height: 1rem;
        width: 100%;
        transform: skewX(-30deg) rotateX(45deg);
        transform-origin: 100% 100%;
        z-index: -10;
        overflow: hidden;
    }
    &:hover::before{
        transition: background 0.4s ease-out;
        background-position: 0 0;
    }
    &:hover::after {
        transition: left 0.4s ease-out;
        background-color: var(--primary-color-lighten);
        left: calc(100% - 0.25rem);
    }
    &::after {
        content: "";
        /* display: inline-flex; */
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: var(--primary-color);
        height: 0.25rem;
        width: 0.25rem;
    }
`;

const StyledUL = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;

    li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 0.6rem;
    }
    li span {
        flex: 0 0;
    }
    li span.description {
        flex: 1 0 100%;
    }

    .link {
        position: relative;
        bottom: 0;
    }
    .icon {
        font-size: 1.2rem;
        display: inline-flex;
        margin-top: 0.125rem;
    }
    
`;

export default LinksList;
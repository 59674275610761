import styled from "styled-components";

function Header() {
    return (
        <HeaderStyled>
            <div>
                Luca P.
            </div>
            <div>
                <a href="mailto:luca.paolella@gmail.com">luca.paolella@gmail.com</a>
            </div>
        </HeaderStyled>
    )
}

const HeaderStyled = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid var(--primary-color);

    div {
        padding: 0.25rem;
        padding-top: 1.5rem;
    }
    div:first-child {
        font-size: 2rem;
        font-weight: 900;
        padding-left: 2rem;
        padding-bottom: 1rem;
    }
    div:last-child {
        font-size: 0.75rem;
        align-self: flex-end;
    }
`;

export default Header;
import styled from 'styled-components';

import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

import { breakpoints } from './breakpoints';

function App() {
  return (
    <>
      <SpacerDiv>
      </SpacerDiv>
      <AppContainerDiv>
        <Header></Header>
        <Main></Main>
        <Footer></Footer>
      </AppContainerDiv>
      <SpacerDiv>
      </SpacerDiv>
    </>
  );
}

const SpacerDiv = styled.div`
  flex: 1 0 15%;
  height: 100%;
  @media screen and (${breakpoints.mobile}) {
    flex: 0 0 2.5%;
  }
  &:last-of-type {
    background-color: var(--surface-1);
  }
`;

const AppContainerDiv = styled.div`
  flex: 1 0 70%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  
  @media screen and (${breakpoints.mobile}) {
    flex: 1 0 95%;
  }
`;

export default App;
